




import { Bar } from 'vue-chartjs/legacy'
import 'chart.js/auto'
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'BarGraph',
  components: { Bar }
})
export default class BarGraph extends Vue {
  @Prop({ default: () => [] }) data!: any
  @Prop({ default: () => {} }) options!: any
  @Prop({ default: 'bar-graph', required: true }) chartId!: string
}

