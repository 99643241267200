

















































import SalesHeadlines from '@/components/SalesHeadlines.vue'
import SalesByTypeGraph from '@/components/SalesByTypeGraph.vue'
import RetentionRatesGraph from '@/components/RetentionRatesGraph.vue'
import SalesByDept from '@/components/SalesByDept.vue'
import SalesByPerson from '@/components/SalesByPerson.vue'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  name: 'SalesDashboard',
  components: { SalesHeadlines, SalesByTypeGraph, RetentionRatesGraph, SalesByDept, SalesByPerson }
})
export default class SalesDashboard extends Vue {
  mounted () {

  }
}

