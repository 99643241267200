



















































































import { Api, HeadlineSubscriptionProductSplit, HeadlineUserTypeSplit, SchoolOrgType } from '@/edshed-common/api'
import { Locale, locales } from '@/edshed-common/i18n'
import Vue from 'vue'
import Component from 'vue-class-component'
import dateFormat from 'dateformat'

@Component({
  name: 'SalesHeadlines'
})
export default class SalesHeadlines extends Vue {
  private locale: Locale | null = null
  private orgType: SchoolOrgType | null = null
  private wordsSpelled = 0
  private quizzesPlayed = 0
  private activeSubscriptions: HeadlineSubscriptionProductSplit | null = null
  private activeUsers: HeadlineUserTypeSplit | null = null

  private isCalculating: boolean = false
  private lastUpdated: Date | null = null

  private reloadTimer: any | null = null

  dateFormat = dateFormat
  locales = locales
  SchoolOrgType = SchoolOrgType

  mounted () {
    this.getHeadlines()
  }

  async getHeadlines () {
    if (this.reloadTimer !== null) {
      clearTimeout(this.reloadTimer)
    }

    const data = await Api.getSalesHeadlines({ locale: this.locale || undefined, organisation_type: this.orgType || undefined })

    this.isCalculating = data.is_calculating
    this.lastUpdated = data.last_calculated === null ? null : new Date(data.last_calculated)

    this.activeSubscriptions = data.active_subscriptions
    this.activeUsers = data.active_users

    this.wordsSpelled = data.words_spelled
    this.quizzesPlayed = data.quizzes_played

    if (this.isCalculating) {
      this.reloadTimer = setTimeout(() => {
        this.getHeadlines()
      }, 10 * 1000)
    }
  }

  getSubscriptionsTooltipData (data: HeadlineSubscriptionProductSplit) {
    return `Spelling: ${Intl.NumberFormat('en').format(data.spelling)}
    Maths: ${Intl.NumberFormat('en').format(data.number)}
    Phonics: ${Intl.NumberFormat('en').format(data.phonics)}
    Literacy: ${Intl.NumberFormat('en').format(data.literacy)}
    Total: ${Intl.NumberFormat('en').format(data.total)}`
  }

  getUsersTooltipData (data: HeadlineUserTypeSplit) {
    return `Admins: ${Intl.NumberFormat('en').format(data.admins)}
    Teachers: ${Intl.NumberFormat('en').format(data.teachers)}
    Pupils: ${Intl.NumberFormat('en').format(data.pupils)}
    Total: ${Intl.NumberFormat('en').format(data.total)}`
  }
}

