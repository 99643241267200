




















































































































































































import { Api, ISO8601Date, Locale, SalesByTypeGraphResponse, SalesProduct, SchoolOrgType } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import moment from 'moment'
import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart as ChartJS } from 'chart.js'
import BarGraph from './graphs/BarGraph.vue'

ChartJS.register(ChartDataLabels)

interface DataSeries {
  product: SalesProduct | null
  orgType: SchoolOrgType | null
  locale: Locale | null
}

@Component({
  name: 'RetentionRatesGraph',
  components: { BarGraph }
})
export default class RetentionRatesGraph extends Mixins(ComponentHelper) {
  options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return this.getTooltipText(context)
          },
          title (tooltipItems: any[]) {
            return tooltipItems[0].dataset.label
          }
        }
      }
    },
    scales: {
      A: {
        type: 'linear',
        display: true,
        position: 'left'
      },
      B: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false
        },
        ticks: {
          max: 1,
          min: 0,
          beginAtZero: true,
          callback: (value, _index, _values) => {
            return Intl.NumberFormat('en', { style: 'percent' }).format(value)
          }
        }
      },
      x: { id: 'C', stacked: true }
    }
  }

  moment = moment

  period: 'day' | 'week' | 'month' | 'year' = 'month'

  start: Date = moment().utc().startOf('month').subtract(12, 'months').toDate()

  end: Date = new Date()

  activeSeries: DataSeries[] = [{
    product: null,
    orgType: null,
    locale: null
  }]

  seriesMap: Map<string, SalesByTypeGraphResponse> = new Map()

  seriesTrigger = 0 // for forced reactivity of map

  productTypes = SalesProduct

  orgTypes = SchoolOrgType

  isAddingNewSeries = false

  orgType: SchoolOrgType | null = null

  product: SalesProduct | null = null

  region: Locale | null = null

  retentionsByValue: boolean = false

  @Watch('period')
  async onPeriodChanged () {
    const fiveYearsAgo = moment().utc().startOf('year').subtract(5, 'years')
    const earliestData = moment('2019-01-01').utc().startOf('year')

    switch (this.period) {
      case 'day':
        this.start = moment().utc().startOf('day').subtract(30, 'days').toDate()
        this.end = new Date()
        break
      case 'week':
        this.start = moment().utc().startOf('week').subtract(13, 'weeks').toDate()
        this.end = moment().utc().endOf('week').toDate()
        break
      case 'month':
        this.start = moment().utc().startOf('month').subtract(12, 'months').toDate()
        this.end = new Date()
        break
      case 'year':
        this.start = fiveYearsAgo.isBefore(earliestData) ? earliestData.toDate() : fiveYearsAgo.toDate()
        this.end = moment().utc().endOf('year').toDate()
        break
    }

    this.seriesMap.clear()

    await this.getSeriesData()
  }

  getTooltipText (context) {
    if (context.datasetIndex % 3 === 2) {
      return `${this.retentionsByValue ? this.formatCurrency(context.parsed.y, 'gbp') : context.parsed.y} (${Intl.NumberFormat('en', { style: 'percent' }).format(1 - context.chart.config.data.datasets[context.datasetIndex - (context.datasetIndex % 3)].data[context.dataIndex])})` // lost
    } else if (context.datasetIndex % 3 === 1) {
      return `${this.retentionsByValue ? this.formatCurrency(context.parsed.y, 'gbp') : context.parsed.y} (${Intl.NumberFormat('en', { style: 'percent' }).format(context.chart.config.data.datasets[context.datasetIndex - (context.datasetIndex % 3)].data[context.dataIndex])})` // retained
    } else if (context.datasetIndex % 3 === 0) {
      return `${Intl.NumberFormat('en', { style: 'percent' }).format(context.parsed.y)}` // percent
    }
  }

  datesChanged () {
    this.seriesMap.clear()

    // correct for DST since datepicker insists on making that adjustment
    this.start = moment(this.start).add(moment(this.start).utcOffset(), 'minutes').utc().toDate()
    this.end = moment(this.end).add(moment(this.end).utcOffset(), 'minutes').utc().toDate()

    this.getSeriesData()
  }

  async mounted () {
    await this.getSeriesData()
  }

  async getSeriesData () {
    for (const series of this.activeSeries) {
      const seriesKey = `${series.locale || 'all'}:${series.orgType || 'all'}:${series.product || 'all'}`

      if (!this.seriesMap.has(seriesKey)) {
        try {
          const data = await Api.getSalesByTypeGraph({
            product: series.product || undefined,
            locale: series.locale || undefined,
            organisation_type: series.orgType || undefined,
            period: this.period,
            start: this.start,
            end: this.end
          })

          this.seriesMap.set(seriesKey, data)
          this.seriesTrigger++
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  lineColours = ['#a5d5d5', '#fde3ce', '#afacca', '#c1d4a9']

  get seriesData () {
    if (this.seriesTrigger === 0) {
      return {
        datasets: [],
        labels: []
      }
    }
    const graphData = {
      datasets: [] as any[],
      labels: [] as string[]
    }

    const labelMap: Map<string, 1> = new Map()
    let stackId = 0

    for (const series of this.activeSeries) {
      const seriesKey = `${series.locale || 'all'}:${series.orgType || 'all'}:${series.product || 'all'}`
      const seriesCache = this.seriesMap.get(seriesKey)

      if (!seriesCache) {
        continue
      }

      graphData.datasets.push({
        type: 'line',
        backgroundColor: this.lineColours[stackId % this.lineColours.length],
        label: `${seriesCache.locale || ''} ${seriesCache.product === null ? 'All products' : seriesCache.product} ${seriesCache.organisation_type || ''} - Retention Rate`,
        yAxisID: 'B',
        data: this.retentionsByValue ? seriesCache.data.map((r, i) => r.value_due === 0 ? null : (r.value_renewed / r.value_due)) : seriesCache.data.map((r, i) => r.subscriptions_due === 0 ? null : (r.subscriptions_renewed / r.subscriptions_due)),
        fill: false,
        borderColor: this.lineColours[stackId % this.lineColours.length],
        segment: {
          borderDash: [10, 5]
        },
        pointRadius: 5,
        tension: 0,
        spanGaps: true,
        datalabels: {
          labels: {
            value: {
              align: 'top',
              color (ctx) {
                return 'black'
              },
              formatter (value, ctx) {
                return `${(value * 100).toFixed(0)}%`
              }
            }
          }
        }
      })

      const type = this.retentionsByValue
      const that = this

      graphData.datasets.push({
        label: `${seriesCache.locale || ''} ${seriesCache.product === null ? 'All products' : seriesCache.product} ${seriesCache.organisation_type || ''} - Subscriptions Retained`,
        yAxisID: 'A',
        backgroundColor: '#c2d5a8',
        data: seriesCache.data.map((r, i) => type ? r.value_renewed : r.subscriptions_renewed),
        stack: stackId,
        order: 2,
        datalabels: {
          labels: {
            value: {
              color (ctx) {
                return 'black'
              },
              display (ctx) {
                return that.activeSeries.length === 1
              },
              formatter (value, ctx) {
                return type ? that.formatCurrency(value, 'gbp') : value
              }
            }
          }
        }
      })

      graphData.datasets.push({
        label: `${seriesCache.locale || ''} ${seriesCache.product === null ? 'All products' : seriesCache.product} ${seriesCache.organisation_type || ''} - Subscriptions Lost`,
        yAxisID: 'A',
        backgroundColor: '#e3a7c0',
        data: seriesCache.data.map((r, i) => this.retentionsByValue ? (r.value_due - r.value_renewed) : (r.subscriptions_due - r.subscriptions_renewed)),
        stack: stackId,
        order: 2,
        datalabels: {
          labels: {
            value: {
              color (ctx) {
                return 'black'
              },
              display (ctx) {
                return that.activeSeries.length === 1
              },
              formatter (value, ctx) {
                return type ? that.formatCurrency(value, 'gbp') : value
              }
            }
          }
        }
      })

      seriesCache.data.forEach((r) => {
        labelMap.set(this.dateToLabel(r.period_start), 1)
      })

      stackId++
    }

    for (const key of labelMap.keys()) {
      graphData.labels.push(key)
    }

    return graphData
  }

  get yearOptions () {
    const options: { start: Date, end: Date, text: string }[] = []
    const date = moment('2019-01-01').utc().startOf('day')

    while (date.isSameOrBefore(moment().utc(), 'year')) {
      options.push({ start: date.toDate(), end: moment(date).utc().endOf('year').toDate(), text: date.format('YYYY') })
      date.add(1, 'year')
    }

    return options
  }

  dateToLabel (date: ISO8601Date | Date) {
    return this.period === 'day' ? moment(date).utc().format('DD/MM/YY')
      : this.period === 'week' ? `Week ${moment(date).utc().subtract(1, 'week').format('w')}`
        : this.period === 'month' ? moment(date).utc().format('MMM YYYY')
          : this.period === 'year' ? moment(date).utc().format('YY') : ''
  }

  addSeriesClicked () {
    this.isAddingNewSeries = true
  }

  closeAddDataSeries () {
    this.isAddingNewSeries = false
    this.resetAddSeries()
  }

  resetAddSeries () {
    this.product = null
    this.orgType = null
    this.region = null
  }

  addDataSeries () {
    this.activeSeries.push({
      product: this.product,
      orgType: this.orgType,
      locale: this.region
    })

    this.getSeriesData()
  }

  removeDataSeries (i: number) {
    console.log({ i })
    this.activeSeries.splice(i, 1)
    this.getSeriesData()
  }
}

