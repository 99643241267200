


































































































































































import { Api, ISO8601Date, SalesByPersonResponse } from '@/edshed-common/api'
import moment from 'moment'
import Component from 'vue-class-component'
import { Vue, Watch } from 'vue-property-decorator'
import { SalesSourcePersons } from '@/edshed-common/api/types/sales'
import { Chart } from 'chart.js'
import BarGraph from './graphs/BarGraph.vue'

interface DataSeries {
  person: string| null
  sale: string| null
}

@Component({
  name: 'SalesByPerson',
  components: { BarGraph }
})
export default class SalesByPerson extends Vue {
  options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const person = context.dataset.label.split('-')[0].trim()
            const stackId = context.dataset.stack

            const total = context.chart.config.data.datasets
              .filter(ds => ds.stack === stackId)
              .map(ds => ds.data[context.dataIndex])
              .reduce((a, b) => a + b, 0)

            const merch = context.chart.config.data.datasets
              .filter(ds => ds.stack === stackId && ds.key.split(':')[1] === 'merch')
              .map(ds => ds.data[context.dataIndex])
              .reduce((a, b) => a + b, 0)

            const subscriptions = context.chart.config.data.datasets
              .filter(ds => ds.stack === stackId && ds.key.split(':')[1] === 'subscriptions')
              .map(ds => ds.data[context.dataIndex])
              .reduce((a, b) => a + b, 0)

            const merchPercent = Intl.NumberFormat('en', { style: 'percent' }).format(merch / total)
            const subsPercent = Intl.NumberFormat('en', { style: 'percent' }).format(subscriptions / total)

            return [person,
              (context.dataset.key.split(':')[1] === 'subscriptions' ? '> ' : '') + `Subscriptions: ${Intl.NumberFormat('en', { style: 'currency', currency: 'GBP' }).format(subscriptions)}  (${subsPercent})`,
              (context.dataset.key.split(':')[1] === 'merch' ? '> ' : '') + `Merchandise: ${Intl.NumberFormat('en', { style: 'currency', currency: 'GBP' }).format(merch)} (${merchPercent})`,
              `Total: ${Intl.NumberFormat('en', { style: 'currency', currency: 'GBP' }).format(total)}`
            ]
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          callback: (value, _index, _values) => {
            return Intl.NumberFormat('en', { style: 'currency', currency: 'GBP' }).format(value)
          }
        },
        stacked: true
      },
      x: {
        id: 'C',
        stacked: true
      }
    }
  }

  moment = moment

  period: 'day' | 'week' | 'month' | 'year' = 'month'

  start: Date = moment().utc().startOf('month').subtract(12, 'months').toDate()

  end: Date = new Date()

  activeSeries: DataSeries[] = [{
    person: null,
    sale: null
  }]

  seriesMap: Map<string, SalesByPersonResponse> = new Map()

  seriesTrigger = 0 // for forced reactivity of map

  isAddingNewSeries = false

  persons = SalesSourcePersons

  person: string | null = null

  sales = ['merch', 'subscriptions']

  sale: string | null = null

  colours = ['#a5d5d5', '#fdcb6e', '#e3bfc0', '#c2d5a8', '#e3dcc1', '#c2d68b']
  darkenedColours = ['#3c8080', '#b37502', '#913f41', '#64803e', '#908041', '#6b822e']

  @Watch('period')
  async onPeriodChanged () {
    const fiveYearsAgo = moment().utc().startOf('year').subtract(5, 'years')
    const earliestData = moment('2019-01-01').utc().startOf('year')

    switch (this.period) {
      case 'day':
        this.start = moment().utc().startOf('day').subtract(30, 'days').toDate()
        this.end = new Date()
        break
      case 'week':
        this.start = moment().utc().startOf('week').subtract(13, 'weeks').toDate()
        this.end = moment().utc().endOf('week').toDate()
        break
      case 'month':
        this.start = moment().utc().startOf('month').subtract(12, 'months').toDate()
        this.end = new Date()
        break
      case 'year':
        this.start = fiveYearsAgo.isBefore(earliestData) ? earliestData.toDate() : fiveYearsAgo.toDate()
        this.end = moment().utc().endOf('year').toDate()
        break
    }

    this.seriesMap.clear()

    await this.getActiveSeriesData()
  }

  datesChanged () {
    this.seriesMap.clear()

    // correct for DST since datepicker insists on making that adjustment
    this.start = moment(this.start).add(moment(this.start).utcOffset(), 'minutes').utc().toDate()
    this.end = moment(this.end).add(moment(this.end).utcOffset(), 'minutes').utc().toDate()

    this.getActiveSeriesData()
  }

  async mounted () {
    await this.getActiveSeriesData()
  }

  async getActiveSeriesData () {
    for (const series of this.activeSeries) {
      await this.getSeriesData(series)
    }

    this.seriesTrigger++
  }

  async getSeriesData (series: DataSeries) {
    for (const series of this.activeSeries) {
      const seriesKey = `${series.person || 'all'}:${series.sale || 'all'}`

      if (!this.seriesMap.has(seriesKey)) {
        try {
          const data = await Api.getSalesByPerson({
            person: series.person || undefined,
            period: this.period,
            start: this.start,
            end: this.end
          })

          this.seriesMap.set(seriesKey, data)
          this.seriesTrigger++
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  get seriesData () {
    try {
      if (this.seriesTrigger === 0) {
        return {
          datasets: [],
          labels: []
        }
      }

      const graphData = {
        datasets: [] as any[],
        labels: [] as string[]
      }
      const personsArray = [...SalesSourcePersons]
      const labelMap: Map<string, 1> = new Map()

      const subsData = []
      const merchData = []

      for (const series of this.activeSeries) {
        const seriesKey = `${series.person || 'all'}:${series.sale || 'all'}`
        const seriesCache = this.seriesMap.get(seriesKey)

        if (!seriesCache) {
          continue
        }

        const sale = series.sale || 'total'

        personsArray.forEach((pers, i) => {
          if (!subsData[pers]) {
            subsData[pers] = []
          }

          if (!merchData[pers]) {
            merchData[pers] = []
          }

          seriesCache.data.forEach((r) => {
            r.personsData.filter(p => p.person === pers).map((pd) => {
              if (sale === 'subscriptions' || sale === 'total') {
                subsData[pers].push(pd.subscriptions)
              }

              if (sale === 'merch' || sale === 'total') {
                merchData[pers].push(pd.merch)
              }
            })
          })

          if (!this.person || this.person === pers) {
            if (subsData[pers].find(d => d > 0) && !graphData.datasets.find(d => d.key === `${pers}:subscriptions`)) {
              graphData.datasets.push({
                key: `${pers}:subscriptions`,
                label: `${pers} - Subscriptions`,
                backgroundColor: this.darkenedColours[i],
                data: subsData[pers],
                stack: i
              })
            }

            if (merchData[pers].find(d => d > 0) && !graphData.datasets.find(d => d.key === `${pers}:merch`)) {
              graphData.datasets.push({
                key: `${pers}:merch`,
                label: `${pers} - Merch`,
                backgroundColor: this.colours[i],
                data: merchData[pers],
                stack: i
              })
            }
          }
        })

        seriesCache.data.forEach((r) => {
          labelMap.set(this.dateToLabel(r.period_start), 1)
        })
      }

      for (const key of labelMap.keys()) {
        graphData.labels.push(key)
      }

      return graphData
    } catch (err) {
      console.log(err)
    }
  }

  get yearOptions () {
    const options: { start: Date, end: Date, text: string }[] = []
    const date = moment('2019-01-01').utc().startOf('day')

    while (date.isSameOrBefore(moment().utc(), 'year')) {
      options.push({ start: date.toDate(), end: moment(date).utc().endOf('year').toDate(), text: date.format('YYYY') })
      date.add(1, 'year')
    }

    return options
  }

  dateToLabel (date: ISO8601Date | Date) {
    return this.period === 'day' ? moment(date).utc().format('DD/MM/YY')
      : this.period === 'week' ? `Week ${moment(date).utc().subtract(1, 'week').format('w')}`
        : this.period === 'month' ? moment(date).utc().format('MMM YYYY')
          : this.period === 'year' ? moment(date).utc().format('YY') : ''
  }

  addSeriesClicked () {
    this.isAddingNewSeries = true
  }

  closeAddDataSeries () {
    this.isAddingNewSeries = false
    this.resetAddSeries()
  }

  resetAddSeries () {
    this.person = null
    this.sale = null
  }

  addDataSeries () {
    this.activeSeries.push({
      person: this.person,
      sale: this.sale
    })

    this.getActiveSeriesData()
  }

  removeDataSeries (i: number) {
    console.log({ i })
    this.activeSeries.splice(i, 1)
    this.getActiveSeriesData()
  }
}

