




















































































































































































import { Api, ISO8601Date, Locale, SalesByTypeGraphResponse, SalesProduct, SchoolOrgType } from '@/edshed-common/api'
import { locales } from '@/edshed-common/i18n'
import moment from 'moment'
import Component from 'vue-class-component'
import { Vue, Watch } from 'vue-property-decorator'
import BarGraph from './graphs/BarGraph.vue'

interface DataSeries {
  product: SalesProduct | null
  orgType: SchoolOrgType | null
  locale: Locale | null
}

@Component({
  name: 'SalesByTypeGraph',
  components: { BarGraph }
})
export default class SalesByTypeGraph extends Vue {
  options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false
      },
      tooltip: {
        callbacks: {
          label (context) {
            const stackId = context.dataset.stack
            const total = context.chart.config.data.datasets.filter(ds => ds.stack === stackId).map(ds => ds.data[context.dataIndex]).reduce((a, b) => a + b, 0)
            const percent = Intl.NumberFormat('en', { style: 'percent' }).format(context.raw / total)
            return `${context.dataset.label} - ${Intl.NumberFormat('en', { style: 'currency', currency: 'GBP' }).format(context.raw)} (${percent})`
          }
        }
      }
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          callback: (value, _index, _values) => {
            return Intl.NumberFormat('en', { style: 'currency', currency: 'GBP' }).format(value)
          }
        },
        stacked: true
      },
      x: {
        id: 'C',
        stacked: true
      }
    }
  }

  locales = locales

  moment = moment

  period: 'day' | 'week' | 'month' | 'year' = 'month'

  start: Date = moment().utc().startOf('month').subtract(12, 'months').toDate()

  end: Date = new Date()

  activeSeries: DataSeries[] = [{
    product: null,
    orgType: null,
    locale: null
  }]

  seriesMap: Map<string, SalesByTypeGraphResponse> = new Map()

  seriesTrigger = 0 // for forced reactivity of map

  productTypes = SalesProduct

  orgTypes = SchoolOrgType

  isAddingNewSeries = false

  orgType: SchoolOrgType | null = null

  product: SalesProduct | null = null

  region: Locale | null = null

  fullLiteracy: boolean = true

  @Watch('period')
  async onPeriodChanged () {
    const fiveYearsAgo = moment().utc().startOf('year').subtract(5, 'years')
    const earliestData = moment('2019-01-01').utc().startOf('year')

    switch (this.period) {
      case 'day':
        this.start = moment().utc().startOf('day').subtract(30, 'days').toDate()
        this.end = new Date()
        break
      case 'week':
        this.start = moment().utc().startOf('week').subtract(13, 'weeks').toDate()
        this.end = moment().utc().endOf('week').toDate()
        break
      case 'month':
        this.start = moment().utc().startOf('month').subtract(12, 'months').toDate()
        this.end = new Date()
        break
      case 'year':
        this.start = fiveYearsAgo.isBefore(earliestData) ? earliestData.toDate() : fiveYearsAgo.toDate()
        this.end = moment().utc().endOf('year').toDate()
        break
    }

    this.seriesMap.clear()

    await this.getActiveSeriesData()
  }

  @Watch('fullLiteracy')
  onLiteracyToggleChanged () {
    this.getActiveSeriesData()
  }

  datesChanged () {
    this.seriesMap.clear()

    // correct for DST since datepicker insists on making that adjustment
    this.start = moment(this.start).add(moment(this.start).utcOffset(), 'minutes').utc().toDate()
    this.end = moment(this.end).add(moment(this.end).utcOffset(), 'minutes').utc().toDate()

    this.getActiveSeriesData()
  }

  async mounted () {
    await this.getActiveSeriesData()
  }

  async getActiveSeriesData () {
    for (const series of this.activeSeries) {
      await this.getSeriesData(series)
    }

    this.seriesTrigger++
  }

  async getSeriesData (series: DataSeries) {
    const seriesKey = `${series.locale || 'all'}:${series.orgType || 'all'}:${series.product || 'all'}`

    if (!this.seriesMap.has(seriesKey)) {
      try {
        const data = await Api.getSalesByTypeGraph({
          product: series.product || undefined,
          locale: series.locale || undefined,
          organisation_type: series.orgType || undefined,
          period: this.period,
          start: this.start,
          end: this.end
        })

        this.seriesMap.set(seriesKey, data)
      } catch (err) {
        console.log(err)
      }
    }

    // make sure we have literacy data if we are looking at all products and require a 10% cut
    if (series.product === null && !this.fullLiteracy) {
      await this.getSeriesData({
        product: 'literacy',
        orgType: series.orgType,
        locale: series.locale
      })
    }
  }

  get seriesData () {
    if (this.seriesTrigger === 0) {
      return {
        datasets: [],
        labels: []
      }
    }
    const graphData = {
      datasets: [] as any[],
      labels: [] as string[]
    }

    const labelMap: Map<string, 1> = new Map()
    let stackId = 0

    for (const series of this.activeSeries) {
      const seriesKey = `${series.locale || 'all'}:${series.orgType || 'all'}:${series.product || 'all'}`
      let seriesCache = this.seriesMap.get(seriesKey)

      if (!seriesCache) {
        continue
      }

      if (series.product === null && !this.fullLiteracy) {
        const seriesKey = `${series.locale || 'all'}:${series.orgType || 'all'}:literacy`
        const literacyCache = this.seriesMap.get(seriesKey)

        if (!literacyCache) {
          continue
        }

        seriesCache = {
          ...seriesCache,
          data: seriesCache.data.map((r, i) => ({
            ...r,
            new_sales: r.new_sales - literacyCache.data[i].new_sales * 0.9,
            retention_sales: r.retention_sales - literacyCache.data[i].retention_sales * 0.9,
            upgrade_sales: r.upgrade_sales - literacyCache.data[i].upgrade_sales * 0.9
          }))
        }
      }

      graphData.datasets.push({
        label: `${seriesCache.locale || ''} ${seriesCache.product === null ? 'All products' : seriesCache.product} ${seriesCache.organisation_type || ''} - New Sales`,
        backgroundColor: '#c2d5a8',
        data: seriesCache.data.map((r, i) => r.new_sales),
        stack: stackId
      })

      graphData.datasets.push({
        label: `${seriesCache.locale || ''} ${seriesCache.product === null ? 'All products' : seriesCache.product} ${seriesCache.organisation_type || ''} - Retentions`,
        backgroundColor: '#a5d5d5',
        data: seriesCache.data.map((r, i) => r.retention_sales),
        stack: stackId
      })

      graphData.datasets.push({
        label: `${seriesCache.locale || ''} ${seriesCache.product === null ? 'All products' : seriesCache.product} ${seriesCache.organisation_type || ''} - Upgrades`,
        backgroundColor: '#e3a7c0',
        data: seriesCache.data.map((r, i) => r.upgrade_sales),
        stack: stackId
      })

      seriesCache.data.forEach((r) => {
        labelMap.set(this.dateToLabel(r.period_start), 1)
      })

      stackId++
    }

    for (const key of labelMap.keys()) {
      graphData.labels.push(key)
    }

    return graphData
  }

  get yearOptions () {
    const options: { start: Date, end: Date, text: string }[] = []
    const date = moment('2019-01-01').utc().startOf('day')

    while (date.isSameOrBefore(moment().utc(), 'year')) {
      options.push({ start: date.toDate(), end: moment(date).utc().endOf('year').toDate(), text: date.format('YYYY') })
      date.add(1, 'year')
    }

    return options
  }

  dateToLabel (date: ISO8601Date | Date) {
    return this.period === 'day' ? moment(date).utc().format('DD/MM/YY')
      : this.period === 'week' ? `Week ${moment(date).utc().subtract(1, 'week').format('w')}`
        : this.period === 'month' ? moment(date).utc().format('MMM YYYY')
          : this.period === 'year' ? moment(date).utc().format('YY') : ''
  }

  addSeriesClicked () {
    this.isAddingNewSeries = true
  }

  closeAddDataSeries () {
    this.isAddingNewSeries = false
    this.resetAddSeries()
  }

  resetAddSeries () {
    this.product = null
    this.orgType = null
    this.region = null
  }

  addDataSeries () {
    this.activeSeries.push({
      product: this.product,
      orgType: this.orgType,
      locale: this.region
    })

    this.getActiveSeriesData()
  }

  removeDataSeries (i: number) {
    console.log({ i })
    this.activeSeries.splice(i, 1)
    this.getActiveSeriesData()
  }
}

